<template>
<div class='page'>
   <div class="banner"> 
            <div class="w">
                <div class="info">
                    <div class="title">INDUSTRIAL</div>
                    <p class="title1">The main purpose of industrial prototyping is to take the product from drawings and into the real world.</p>
                    
                  
                </div>
            </div>
        </div>
 
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
   <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import myhead from "../../components/myHead.vue";
import bread from '../../components/bread.vue'
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue";
import capabilities from "../../components/Capabilities.vue";
export default {
  components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    bread,
    capabilities
  },
  data() {
    return {
          hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
      CapabilitiesList: {
        H1: "INDUSTRIAL POST-PROCESSING CAPABILITIES",
        lists: [
          { title: "Anodizing" },
          { title: "Passivation" },
          { title: "Electroplating" },
          { title: "Powder Coating" },
          { title: "Insert Installation" },
          { title: "Heat Treatment" },
            { title: "Sandblasting" },
              { title: "Painting" },
        ],
        img: require("../../assets/img/industrialPL1.png")
      },
      industriesheadLists: {
        ref: "INDUSTRIAL",
        H1: "INDUSTRIAL",
        info: `HLH works with leading industrial products makers to accelerate prototyping, dynamically scale manufacturing capacity, and simplify their supply chain. We have fulfilled the customers’ production plan with high-tech CNC machining processes for rapid prototyping, product inspecting, custom tooling, and custom design manufacturing. Our flexible development service gives you the opportunity to develop innovative products for any industry.`,
        imgs: [
          {
            src: require("../../assets/img/industrialmodel1.png")
          }
        ],
        h2: "Top Industrial Applications",
        data: [
          {
            content:  "Plant floor manufacturing : Custom assembly fixtures /equipment/jig details"
          },
          {
            content:  "Small batch prototypes with high-precision tolerances"
          },{
            content:  "Rapid prototyping of industrial devices using CNC manufacturing"
          },
          {
            content: "Industrial robotics"
          }
        ]
      },
      ApplicationsLists: {
        H1: "WHY INDUSTRIAL COMPANIES CHOOSE HLH？",
        data: [
          {
            img: require("../../assets/img/industrialmodel11.png"),
            title: "Optimized structural design",
            info: "Due to the limitations of traditional processing technology, there are defects in the design of parts and components. We have a professional cross-border technical team to optimize the structural design through CNC technology to meet the design requirements and achieve the purpose of simplifying processing."
          },
          {
            img: require("../../assets/img/industrialmodel22.png"),
            title: "Improve material properties",
            info: "HLH offers a wide selection of CNC machining materials, many of which can replace those used in traditional processes, or even outperform certain functional properties."
          },
          {
            img: require("../../assets/img/industrialmodel33.png"),
            title: "Industry Leading Quality",
            info: "Quality services include material certifications, certificates of conformity, advanced inspection reports and more."
          }
        ]
      }
    };
  },

  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {}
};
</script>
<style scoped lang="scss">
.page {
}

  .banner{
        background: url('~@/assets/img/industrialbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
            display: flex;
            justify-content: flex-end;
            margin-top: 87px;
        }
    
        
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #666666;
          
      
        }
      
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: #333333;
                font-weight: bold;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
        position: absolute;
    right: 38vw;
    top: 26vw;
     

    }
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
        font-size: 23px;
        font-weight: bold;
          margin-bottom: 6vw;
    } 
    .title1{
         font-size: 17px;
    width: 58vw;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 500px;
          background-position: 22% 61% !important
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 35px;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:37vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
        margin-bottom: 54px;
    }
   .title1{
        font-size: 22px;
        width:31vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 90%;
    }
    .title{
        font-size: 50px;
        margin-bottom: 54px;
    }
    .title1{
           font-size: 24px;
        width: 435px;
          line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>